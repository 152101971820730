
import { apiService } from '@/services/api.service';
import { kioskModule } from '@/store/kiosk.vuex-module';
import differenceInYears from 'date-fns/differenceInYears/index';
import { Options, Vue } from 'vue-class-component';

type PostWaiverMessage = { EntityHierarchyKey: number; Message: string; Name: string };

type OrderConsent = {
	ConsentKey: number;
	FirstName: string;
	LastName: string;
	Age: number;
	IsParticipating: boolean;
	UserConsentOrderEntityTimeKey: number;
};
@Options({
	name: 'CompleteView',
	components: {},
})
export default class CompleteView extends Vue {
	isLoading = false;
	postWaiverMsgs: PostWaiverMessage[] = [];
	participants: {
		name: string;
		age: number;
		isParticipating: boolean;
	}[] = [];
	consents: {
		firstName: string;
		lastName: string;
		// isParticipating: boolean;
		age: number;
	}[] = [];
	linkCopied = false;

	get unassigned() {
		return kioskModule.unassignedWaiver;
	}

	created() {
		kioskModule.updateLastPage('Finished');
		this.participants = this.getParticipantList();
		if (!this.unassigned) {
			this.isLoading = true;
			apiService
				.get('getCheckinMessages', {
					orderEntityTimeKeys: Object.keys(kioskModule.activityParticipants).join(','),
				})
				.then((res: PostWaiverMessage[]) => {
					this.postWaiverMsgs = res;
				})
				.finally(() => (this.isLoading = false));
		}
		// reset session if not in dev mode
		if (!process.env.VUE_APP_DEV_MODE) {
			kioskModule.resetSession();
		}
	}

	// async copy url to clipboard
	copyLinkUrl() {
		navigator.clipboard
			.writeText(this.waiverUrl)
			.then(
				function () {
					console.debug('Copy was successful');
					return true;
				},
				function (err) {
					console.error('Could not copy waiver URL: ', err);
					return false;
				},
			)
			.then((res) => (this.linkCopied = res));
	}

	restartWaiver() {
		kioskModule.resetSession();
		window.location.replace(this.waiverUrl);
	}

	get waiverUrl() {
		// return `${window.location.origin}/checkin/${this.$route.params.onsite}/${this.$route.params.clientCode}/${this.$route.params.orderKey}`;
		return window.location.href.replace('/complete', '');
	}

	getParticipantList() {
		const buildEntry = (first: string, last: string, dob: Date, participating: boolean) => {
			return {
				name: `${first.slice(0, 1).toUpperCase()}${first.slice(1)} ${last.slice(0, 1).toUpperCase()}${last.slice(1)}`,
				age: differenceInYears(new Date(), dob),
				isParticipating: participating,
			};
		};

		const checkedInIds = Array.from(new Set(Object.values(kioskModule.activityParticipants).flat())); // all checked in IDs

		const participants = [buildEntry(kioskModule.consenter.firstName, kioskModule.consenter.lastName, new Date(kioskModule.consenter.dateOfBirth.formatted as string), kioskModule.consenter.isParticipating)];

		kioskModule.minorParticipants
			.filter((m) => !m.isDeleted)
			.forEach((m) => {
				if (checkedInIds.includes(m.guid)) participants.push(buildEntry(m.firstName, m.lastName, new Date(m.dateOfBirth.formatted?.replaceAll('-', '/') as string), true));
			});

		return participants;
	}
}
